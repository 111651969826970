.white-header{
    background-color:#ffffff;
}
.white-header .header-menu ul li.menu-item a, .lang-menu ul li a {
    color: #444444;
}

.border-bottom-black{
    border-bottom:1px solid #808080;
}

.header-hero-image{
    object-fit:cover;
    width: 100%;
    min-height:200px;
}

.breadcrumbs{
    background-color: #e3e3e3;
    font-size:12px;
}

.breadcrumbs span{
    color: #444444;
}

.breadcrumbs a{
    color: #3b61a6;
}

.main-content{
    background-color: #f8f8f8;
}

.main-content h1{
    font-family: 'PFSquareSansProMedium';
    font-size: 52px;
    font-weight: 500;
}

.main-content .main-text p {
    font-family: Arial;
    font-size: 18px;
}


header.white-header.sticky{
    background-color: #ffffff;
}

.service-box-left .service-box-wrapper{
    background-image: url(../images/service/Evrizonikotita_imag1.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding:120px 0;
}
.service-box-right .service-box-wrapper{
    background-image: url(../images/service/Evrizonikotita_imag2.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding:120px 0;
}

.service-box-left a{
    font-family: 'PFSquareSansProMedium';
    font-size: 51px;
    font-weight: 500;
    color: #fff;
    
}

.service-box-right a{
    font-family: 'PFSquareSansProMedium';
    font-size: 51px;
    font-weight: 500;
    color: #fff;
    background-color: #3b60a6da;
    padding:100px;
}


.map-serach-field {
    width: 100%;
    height: 55px;
    margin: 0 0 0.5px;
    padding: 18px 18px 18px 60px;
    border: solid 1px #808080;
    background-color: #fff;
    font-family: Arial;
    font-size: 16px;
    color: #3b61a6;
    background-image:  url(../images/search.png);
    background-position: 14px 14px;
    background-repeat: no-repeat;
}

.map-container {
    height: 1000px;
}

.results-left-box p{
    font-family: 'PFSquareSansProMedium';
    font-size: 24px;
    font-weight: 500;
    color: #3b61a6;
}


.results-right-box h2{
    font-family: 'PFSquareSansProMedium';
    font-size: 32px;
    font-weight: 500;
    color: #3b61a6;
    line-height: 1.5;
    margin-bottom:30px;
}

.results-right-box ul{
    padding:0; 
}
.results-right-box ul li{
    font-family: Arial;
    font-size: 18px;
    color: #444;
    list-style-type: none;
    background-image:  url(../images/tick.png);
    background-position: left;
    background-repeat: no-repeat;
    padding-left:40px;
    line-height: 1.78;
}

.search-area span:nth-child(1){
    background-color: #3b61a6;
    color: #ffffff;
    font-family: 'Arial';
    font-size: 21px;
    padding: 20px 10px;
    font-weight: bold;
    float: left;
    margin-right:10px; 
}

.search-area span:nth-child(2){
    font-family: Arial;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.78;
    color: #444;
    float: left;
    margin-left:10px; 
    margin-top:5px;
  }
 
.search-results{
    background-color:#ffffff;
}

.inner main{
    padding-top:96px;
}

header{
    background-color: #ffffff;
}
.home header{
    background-color: transparent;
}


.header-menu ul li.menu-item a{
    color: #444444;
}
.home .header-menu ul li.menu-item a{
    color: #ffffff;
}

.home .inner-logo{
    display:none;
}

.inner .home-logo{
    display:none;
}

.inner header  a:before {
    background-color: var(--color-dark);
}

header.sticky{
    background-color: var(--color-blue) !important;
}

header.sticky .header-menu ul li.menu-item a, 
header.sticky .lang-menu ul li a{
    color: var(--color-white) !important;
}


 


header.sticky .home-logo{
    display:block;
}
header.sticky .inner-logo{
    display:none;
}

header.sticky .inner-logo{
    display:none;
}

header.sticky  a:before {
    background-color: var(--color-white);
}